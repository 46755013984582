import React from 'react'

import { BlockTitle, Container, Text } from 'components/common/styles'

import styled from '@emotion/styled'
import { between } from 'polished'

type ColoredBlockProps = {
  colored: boolean
}

export const ColoredBlock = styled.div<ColoredBlockProps>`
  background-color: ${props => props.colored ? '#E4F0EF' : 'transparent'};
  align-self: stretch;
  
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LocalContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 80px;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 60px;
  }
`
const Info = styled.div`
  width: 50%;
  padding-right: 20px;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 40px;
  }
`
const Title = styled(BlockTitle)``
const Description = styled(Text)`
  margin-top: 32px;
`
const GraphSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const GraphContainer = styled.div`
  font-size: 100px;
  position: relative;
  
  margin: .18em 1.01em .4em .9em;
  
  @media screen and (max-width: 1280px) {
    font-size: ${between('60px', '80px', '768px', '1280px')};
  }
  
  @media screen and (max-width: 768px) {
    font-size: calc((100vw - 40px) / 7);
  }
`
const Circle = styled.div`
  width: 4.08em;
  height: 4.08em;
  border: .7em solid #D5EDEA;
  border-radius: 50%;
`
const CentralText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: .22em;
  line-height: 1.5;
  font-weight: 600;
  color: ${props => props.theme.colors.green100};
`
const Items = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const AbstractItem = styled.div`
  background: #F9F8F7;
  box-shadow: 0 .05em .14em rgba(14, 61, 57, 0.2);
  border-radius: .4em;
  padding: .3em .24em .24em .24em;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  position: absolute;
`
const ItemTop = styled.div`
  position: relative;
`
const UpTo = styled.div`
  position: absolute;
  top: 0;
  font-size: .16em;
  line-height: 1.5;
  color: ${props => props.theme.colors.green100};
`
const Percent = styled.div`
  font-size: .57em;
  line-height: 1.5;
  color: ${props => props.theme.colors.green100};
  font-weight: bold;
  padding-top: .1em;
`
const Term = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`
const Schedule = styled.div`
  border-radius: 1em;
  background: #E4F1EF;
  color: ${props => props.theme.colors.green100};
  font-size: .16em;
  line-height: 1.5;
  font-weight: 600;
  padding: .44em .875em;
  margin-right: 1em;
  @media screen and (max-width: 1000px) {
    margin-right: 0;
  }
`
const Payoff = styled.div`
  font-size: .16em;
  line-height: 1.5;
  color: #1d1e21;
`
const ItemWeekly = styled(AbstractItem)`
  left: -.9em;
  top: -.18em;
  padding: .35em .4em .3em .4em;
  
  ${Percent} {
    font-size: .64em;
  }
`
const ItemBiWeekly = styled(AbstractItem)`
  right: .35em;
  top: .35em;
  transform: translateX(60%);
`
const ItemMonthly = styled(AbstractItem)`
  bottom: -.4em;
  left: 50%;
  transform: translateX(-50%);
`

type Props = {
  colored?: boolean
}

const ReceivesSection: React.FC<Props> = ({ colored = true }) => (
  <ColoredBlock colored={colored}>
    <LocalContainer>
      <Info>
        <Title>Cashback credits</Title>
        <Description>
          Get more cash back when you sign up for automatic payments. The more often you pay off your total balance, the
          more cash back you receive.
        </Description>
      </Info>
      <GraphSide>
        <GraphContainer>
          <Circle />
          <CentralText>RECEIVE</CentralText>
          <Items>
            <ItemWeekly>
              <ItemTop>
                <UpTo>Up to</UpTo>
                <Percent>30%</Percent>
              </ItemTop>
              <Term>
                <Schedule>Weekly</Schedule>
                <Payoff>Autopay</Payoff>
              </Term>
            </ItemWeekly>
            <ItemBiWeekly>
              <ItemTop>
                <UpTo>Up to</UpTo>
                <Percent>25%</Percent>
              </ItemTop>
              <Term>
                <Schedule>Bi-Weekly</Schedule>
                <Payoff>Autopay</Payoff>
              </Term>
            </ItemBiWeekly>
            <ItemMonthly>
              <ItemTop>
                <UpTo>Up to</UpTo>
                <Percent>21%</Percent>
              </ItemTop>
              <Term>
                <Schedule>Monthly</Schedule>
                <Payoff>Autopay</Payoff>
              </Term>
            </ItemMonthly>
          </Items>
        </GraphContainer>
      </GraphSide>
    </LocalContainer>
  </ColoredBlock>
)

export default ReceivesSection
