import React, { useState } from 'react'
import Layout from 'layouts/default'

import InfoSection from 'components/pages/cards/info'
import ReceivesSection from 'components/pages/rewards/receives'
import IntegrationSection from 'components/pages/cards/integration'
import richSnippet from 'components/common/rich-snippet'
import { createCardSchema } from 'utils/richSnippets'
import Comparison from 'components/pages/rewards/comparison'
import QualifySection from 'components/qualify'
import Modal from 'components/common/modal'
import Waitlist from 'components/common/modal/content/waitlist'
import Hero from 'components/hero'
import Button, { ButtonSize } from 'components/common/button'
import CompanyType from 'components/common/enums/company-type'

import Image from 'assets/images/card-page/hero.jpg'
import ImageCircle from 'assets/images/welcome/construction/circle.jpg'

const ConstructionWelcomePage: React.FC = props => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  React.useEffect(() => {
    window.analytics.page('Landing Page', 'Construction')
  }, [])
  return (
    <Layout
      title='High credit line card for construction companies | ComCard'
      description='Receive no personal liability card with high credit limits that will help you save time and money.'
      helmetChildren={richSnippet(createCardSchema())}
      hideHeaderMenu
      disableFixedHeader
    >
      <Modal isOpen={isModalOpen} title="Join waitlist" onClose={() => setIsModalOpen(false)}>
        <Waitlist closeModal={() => setIsModalOpen(false)} defaultIndustry={CompanyType.CONSTRUCTION} />
      </Modal>
      <Hero
        title="Finally, a corporate card tailored for construction businesses of any size."
        subTitle={<Button size={ButtonSize.MEDIUM} onClick={() => {
          setIsModalOpen(true)
          window.analytics.track('Open the waitlist modal', {
            location: 'Construction Landing Hero',
          })
        }}>Join Waitlist</Button>}
        image={Image}
        zIndex={10}
      />
      <InfoSection />
      <ReceivesSection />
      <Comparison />
      <IntegrationSection bottomSpacing={0} />
      <QualifySection
        image={ImageCircle}
        headerText="Sign up to get early access"
        defaultIndustry={CompanyType.CONSTRUCTION}
        locationForEvent="Construction Landing Hero"
      />
    </Layout>
  )
}

export default ConstructionWelcomePage
