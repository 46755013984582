import * as React from 'react'
import { map } from 'lodash-es'
import styled from '@emotion/styled'

import CheckmarkSVG from 'assets/images/icons/checkmark.svg'
import CrossSVG from 'assets/images/icons/cross.svg'

type Props = {
  labels: string[]
  data: {
    [key: string]: React.ReactNode[]
  }
};

const TableDesktop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const Labels = styled.div`
  flex-direction: column;
  width: 25%;
`
const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
  
  font-size: 16px;
  color: #000;
  
  padding-right: 10px;
  
  &:first-of-type {
    margin-top: 63px;
  }
`
const Data = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  
  background-color: #E4F1EF;
  box-shadow: 0 7px 25px rgba(14, 61, 57, 0.2);
  border-radius: 40px;
  //overflow: hidden;
  
  position: relative;
  z-index: 1;
`
const Column = styled.div`
  background: #F9F8F7;
`

const ColumnContainer = styled.div`
  flex: 1;
  background: #F9F8F7;
  position: relative;
  
   &:first-of-type {
    background: #E4F0EF;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    &:after {
        content: "";
        display: block;
        background: #F9F8F7;
        width: 40px;
        height: 40px;
        bottom: 0;
        right: 0;
        z-index: 2;
        position: absolute;      
      }
    > div {
       background: #F9F8F7;
       box-shadow: 0 5px 14px rgba(14, 61, 57, 0.2);
       border-radius: 40px;
       overflow: hidden;
       position: relative;
       z-index: 3;
       
       .header {
         background: #00A095;
         color: #fff;
       }
     }
  }
  
  &:last-of-type {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    > div {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      > div:first-of-type {
        border-top-right-radius: 40px;
      }
      > div:last-of-type {
        border-bottom-right-radius: 40px;
      }
    }
  }
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 64px;
  background: #E4F0EF;
  
  font-size: 18px;
  font-weight: bold;
  color: #00A095;
`
const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 64px;
  background: #F9F8F7;
  
  font-size: 16px;
  color: #1D1E21;
`

const TableMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @media screen and (min-width: 1001px) {
    display: none;
  }
`

const MobileItem = styled.div`
  margin-top: 40px;
  
  &:first-of-type {
    margin-top: 0;
  }
`
const MobileCriteria = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  color: #00A095;
  cursor: pointer;
`
type MobileDataProps = { isOpen: boolean }
const MobileData = styled.div<MobileDataProps>`
  display: ${props => props.isOpen ? 'block' : 'none'}; /* TODO Animation */
  margin-top: 17px;
  background: #F9F8F7;
  box-shadow: 0 5px 14px rgba(14, 61, 57, 0.2);
  border-radius: 40px;
`
const MobileLine = styled.div`
  padding: 16px 40px 21px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &:first-of-type {
    background: #00A095;
    border-radius: 40px;
    
    > div {
      color: #ffffff;
    }
  }
`
const MobileLineTitle = styled.div`
  font-size: 18px;
  line-height: 1.7;
  font-weight: bold;
  color: #00A095;
  margin-bottom: 14px;
`
const MobileItemValue = styled.div`
  font-size: 14px;
  line-height: 1.7;
  color: #1D1E21;
`

const ComparisonTable: React.FC<Props> = ({ labels, data }) => {
  const [openItem, changeOpenItem] = React.useState(0)

  return (
    <>
      <TableDesktop>
        <Labels>
          {labels.map(i => (<Label key={i}>{i}</Label>))}
        </Labels>
        <Data>
          {map(data, (info, col) => (
            <ColumnContainer key={col}>
              <Column>
                <Header className="header">
                  {col}
                </Header>
                {info.map((i, k) => {
                  let content = i
                  if (content == 'Yes') {
                    content = (<img src={CheckmarkSVG} />)
                  }
                  if (content == 'No') {
                    content = (<img src={CrossSVG} />)
                  }
                  return (<Line key={`${col}${k}`}>{content}</Line>)
                })}
              </Column>
            </ColumnContainer>
          ))}
        </Data>
      </TableDesktop>
      <TableMobile>
        {labels.map((val, key) => (
          <MobileItem key={key}>
            <MobileCriteria onClick={() => changeOpenItem(key)}>
              {val}
            </MobileCriteria>
            <MobileData isOpen={openItem === key}>
              {map(data, (info, col) => (
                <MobileLine key={col}>
                  <MobileLineTitle>
                    {col}
                  </MobileLineTitle>
                  <MobileItemValue>
                    {info[key]}
                  </MobileItemValue>
                </MobileLine>
              ))}
            </MobileData>
          </MobileItem>
        ))}
      </TableMobile>
    </>
  )
}

export default ComparisonTable
