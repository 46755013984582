import * as React from 'react'
import { Container } from 'components/common/styles'
import styled from '@emotion/styled'
import ComparisonTable from '../../../comparison-table'

type Props = {
  topSpacing?: number
};

const Title = styled.h2`
  align-self: center;
  font-size: 32px;
  line-height: 50px;
  color: #000;
  font-weight: bold;
  margin-bottom: 45px;
  
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`

const labels = [
  'Type of card',
  'No Annual fee',
  'Rewards fee',
  'Build business credit',
  'Free employee cards',
  'Unique benefits',
  'No rewards cap',
  'No personal guarantee',
  'Receipt management',
  'QuickBooks integration',
]
const data = {
  'ComCard': [
    'Corporate',
    'Yes',
    'Up to 30%',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
    'Yes',
  ],
  'AmEx': [
    'Charge',
    'No',
    'Up to 10%',
    'No',
    'Yes',
    'No',
    'No',
    'No',
    'Yes',
    'No',
  ],
  'Store Cards': [
    'Credit',
    'Yes',
    'Up to 5%',
    'No',
    'N/A',
    'No',
    'No',
    'No',
    'No',
    'No',
  ],
  'Other Banks': [
    'Credit',
    'No',
    'Up to 5%',
    'Yes',
    'No',
    'No',
    'Yes',
    'No',
    'No',
    'No',
  ],
}

const Comparison: React.FC<Props> = ({ topSpacing = 120 }) => {
  return (
    <Container style={{ marginTop: topSpacing, marginBottom: 100 }}>
      <Title>See how we stack up</Title>
      <ComparisonTable labels={labels} data={data} />
    </Container>
  )
}

export default Comparison
